import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import { RequireAuth } from "./requireAuth";

import SitePage   from '../pages/public/Site'
import SignInPage from '../pages/public/SignIn';
import SignUpPage from '../pages/public/SignUp';

import DashboardPage from '../pages/secure/Dashboard';
import CompaniesListForm from '../pages/secure/CompaniesList';
import CompaniesAddForm from '../pages/secure/CompaniesAdd';
import CompaniesDetailPage from '../pages/secure/CompaniesDetail';



export default function MyRoutes(){
   
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<SitePage />}/>
                <Route path='signin' element={<SignInPage />}/>
                <Route path='signup' element={<SignUpPage />}/>

                <Route path="dashboard" element={<RequireAuth><DashboardPage/></RequireAuth>}/>
                
                <Route path="company" element={<RequireAuth><CompaniesListForm/></RequireAuth>} />
                <Route path="company/add" element={<RequireAuth><CompaniesAddForm /></RequireAuth>} />
                <Route path="company/:companyId" element={<RequireAuth><CompaniesDetailPage /></RequireAuth>} />           

            </Routes>
        </BrowserRouter>
    )
}