import baseAPI from './api';
import baseURLs from '../configs/baseURLs';

class CompaniesService{
    constructor() {
        this.api = baseAPI(baseURLs.API_COMPANIES);
    }

    async getAll(){
        const result = await this.api.get('company')
        return result.data;
    }

    async getOne(companyId){
        const result = await this.api.get(`company/${companyId}`)
        return result.data;        
    }

    async add(companyModel) {
        const result = await this.api.post('company', companyModel);
        return result;
    }

    async set(companyModel) {
        const result = await this.api.patch('company', companyModel);
        return result;
    }

    async delete(companyId){
        const result = await this.api.delete(`company/${companyId}`)
        return result.data;        
    }
}

export default CompaniesService;