import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Button, Form, Row, Col, FormGroup, Alert } from 'react-bootstrap';
import  Header  from '../../../shared/header';
import { PageContent } from '../../../shared/styles';
import CompaniesService from '../../../services/companies';


function CompaniesAddForm(){

    let navigate = useNavigate();
    /*
    companyId?: string,
    contratoId?: string,
    cnpj: number,
    razao: string,
    fantasia: string,
    status?: CompanyStatus
    */
    const [ constratoId, setContratoId ] = useState('');
    const [ cnpj, setCnpj] = useState('');
    const [ razao, setRazao] = useState('');
    const [ fantasia, setFantasia ] = useState('');
    const [ status, setStatus ] = useState(200);
    const [ error, setError ] = useState('');
    const [ isLoading, setIsLoading] = useState(false);

    async function handleSubmit(event){
        event.preventDefault();

        if(!cnpj || !razao || !fantasia){
            setError('Preencha todos os campos')
        }else{
            try{
                const service = new CompaniesService();
                await service.add({cnpj, razao, fantasia})

                navigate('/company', { replace: true });

            }catch(error){
                setError(`AddEmpresa: ${error}`)
            }
        }
    }

    function renderError(){
        return(
            <Alert variant='danger'>
                {error}
            </Alert>
        )
    }


    return(
    <>
        <Header/>
        <PageContent>
            <Container>
                <Row>
                    <Col>
                        <h3>Adicionar Empresa</h3>
                        <p>Informe todos os campos para adicionar a empresa</p>
                        {error && renderError()} 
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={12} >
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Form.Label>CNPJ:</Form.Label>
                                <Form.Control type='text' placeholder='Digite o CNPJ' onChange={e => setCnpj(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Nome fantasia (Amigável):</Form.Label>
                                <Form.Control type='text' placeholder='Digite o nome identificador (fantasia)' onChange={e => setFantasia(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Razão Social:</Form.Label>
                                <Form.Control type='text' placeholder='Digite a razão social' onChange={e => setRazao(e.target.value)} />
                            </FormGroup>
                            <Button variant='primary' type='submit'>Adicionar Empresa</Button>
                            <Link className='btn btn-link' to='/company'>Voltar</Link>

                        </Form>
                    </Col>
                </Row>
            </Container>
        </PageContent>
    </>
    )

}

export default CompaniesAddForm;