import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Table, Row, Col } from 'react-bootstrap';
import { PageContent } from '../../../shared/styles';
import  Header  from '../../../shared/header';
import CompaniesService from '../../../services/companies';




function CompaniesListForm(){

    const [ companies, setCompanies] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);

    function RenderLine({company}){
        return(
            <tr key={company.companyId}>
                <td>
                    <Link to={company.companyId}>{company.fantasia}</Link>
                </td>
                <td>{company.razao}</td>
            </tr>
        )
    }

    function RenderTable({companies}){
        return(
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Fantasia</th>
                        <th>Razão Social</th>
                    </tr>
                </thead>
                <tbody>
                    {companies.map((item) => <RenderLine key={item.companyId} company={item} />)}
                </tbody>
            </Table>
        )
    }

    function RenderEmptyRow(){
        return(
               <p>Nenhuma empresa cadastrada.</p>
        )
    }

    useEffect(async ()=> {
        const service = new CompaniesService();

        const result = await service.getAll();
    
        setCompanies(result);

        if(companies.length > 0) setIsLoading(false);
    });


    return(
    <>
        <Header/>
        <PageContent>
            <Container>
                <Row>
                    <Col>
                    <h3>Empresas</h3>
                    </Col>
                    <Col>
                        <Link className='btn btn-success float-right' to='add'>Adicionar Empresa</Link>
                    </Col>
                </Row>
                <p>Relação de empresas cadastradas</p>
                {companies.length === 0 && <RenderEmptyRow/>}
                {!isLoading && <RenderTable companies={companies}/>}
            </Container>
        </PageContent>
    </>
    )

}

export default CompaniesListForm;
