import { PageContent } from '../../../shared/styles';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function SiteForm(){
    return(
    <>
        <PageContent>
            <Container>
                <h2>Site</h2>
                <p>Aqui faremos o SITE</p>
                <Link to='/signin' type='buttom'>Acessar</Link>
                <br/>
                <Link to='/dashboard' type='buttom'>Dashboard</Link>
            </Container>
        </PageContent>
    </>
    )
}

export default SiteForm;