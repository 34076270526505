import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button, Form, Row, Col, FormGroup, Alert } from 'react-bootstrap';
import  Header  from '../../../shared/header';
import { PageContent } from '../../../shared/styles';
import CompaniesService from '../../../services/companies';



function CompaniesDetailPage(){
    let { companyId } = useParams();

    const [ company, setCompany] = useState(null);
    const [ isLoading, setIsLoading] = useState(true);


    function RenderCompany({company}){
        return(
        <>
            <p>CNPJ: {company.cnpj}</p>
            <p>Fantasia: {company.fantasia}</p>
            <p>Nome: {company.razao}</p>
        </>
        )
    }

    async function getCompany(companyId){
        const service = new CompaniesService();

        const result = await service.getOne(companyId);

        setCompany(result);
        if(company) setIsLoading(false);
        //setIsLoading(false);
    }

    useEffect(async ()=> {       
        getCompany(companyId)
    });

 
    return(
    <>
        <Header />
        <PageContent>
            <Container>
                <h3>Dados da empresa</h3>
                {isLoading ? (
                    <p>Carregando...</p>) : (
                    <RenderCompany company={company}/>
                )}
            </Container>
        </PageContent>
    </>

    )


}

export default CompaniesDetailPage;