import { useState } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { Button, Form, Container, Row, Col, Alert, FormGroup } from 'react-bootstrap';
import AccountsService from '../../../services/accounts';
import { BoxContent, BoxForm } from '../../../shared/styles';
import Logo from '../../../assets/logo_white_background.jpg';

function SignUpForm(){

    const [ nome, setNome] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ user, setUser ] = useState('');
    const [ error, setError ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);


    async function handleSubmit(event){
        event.preventDefault();

        if(!nome || !email || !password){
            setError('Informe todos os campos para se cadastrar') 
        } else {
            try{
                const service = new AccountsService;

                let user = await service.signup({nome, email, password});

                setUser(user);
                
            }catch(error){
                console.log(error);
                setError('Ocorreu um erro na criação da conta')
            }
        }
    }

    function renderError(){
        return(
            <Alert variant='danger'>
                {error}
            </Alert>
        )
    }

    return(
      <Container>
          <Row className='justify-content-md-center'>
                <Col xs={12} md={4}>
                    <BoxContent>
                        <img src={Logo} alt='SCEpi' height="50" />
                    </BoxContent>
                    
                    <BoxForm>
                        <h2>Nova Conta</h2>
                        <p>Informe todos os campos para realizar o cadastro.</p>
                        {error && renderError()} 
                        {user && (<Navigate to="/signin" replace={true} />)}

                       <Form onSubmit={handleSubmit}>
                            

                            <FormGroup controlId='formBasicNome'>
                                <Form.Label>Nome:</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder='Digite seu nome' 
                                    onChange={e => setNome(e.target.value)} 
                                />
                            </FormGroup>

                            <FormGroup controlId='formBasicEmail'>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control 
                                    type="Email" 
                                    placeholder='Digite seu e-mail'
                                    onChange={e => setEmail(e.target.value)} 
                                />
                            </FormGroup>

                            <FormGroup controlId='formBasicSenha'>
                                <Form.Label>Senha:</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder='Digite uma senha' 
                                    onChange={e => setPassword(e.target.value)} 
                                />
                            </FormGroup>

                            <div className="d-grid gap-2">
                            <Button type='submit'>
                                Realizar Cadastro
                            </Button>
                            </div>
                        </Form>
                    </BoxForm>               

                    <BoxContent>
                        <Link className='button' to='/signin/'>Voltar para o login</Link>
                    </BoxContent>                    
                </Col>
            </Row>          
      </Container>
    )
}

export default SignUpForm;