import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Header, Logo } from './styles';
import { logout } from '../../services/auth';
import Icone from '../../assets/logo_white_background.jpg'

function HeaderForm(){
    let navigate = useNavigate();

    async function handleLogout(){
        await logout();

        navigate("/",  { replace: true });
    }    

    return(
        <Header>
            <Navbar>
                <Container>
                    <Navbar.Brand href='/dashboard'>
                        <Logo src={Icone} alt='SC/EPI' />
                    </Navbar.Brand>
                    <Nav>
                        <Nav.Link href='/company'>Empresas</Nav.Link>
                        <Nav.Link href='/employees'>Funcionários</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={handleLogout}>Sair</Nav.Link>
                    </Nav>

                </Container>

            </Navbar>


        </Header>
    )
}

export default HeaderForm;