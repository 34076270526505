import React, { useState } from 'react';
import { Button, Form, Container, Row, Col, FormGroup } from 'react-bootstrap';
import Logo from '../../../assets/logo_white_background.jpg';
import { BoxContent, BoxForm } from '../../../shared/styles'
import { Link, useNavigate } from 'react-router-dom';
import AccountsService from '../../../services/accounts'
import { login } from '../../../services/auth';

function SigninForm(){
    let navigate = useNavigate();

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);


    async function handleSubmit(event) {
        event.preventDefault();

        if(!email || !password){
            this.setError('Informe todos os campos para acessar') 
        } else {
            try{
                const service = new AccountsService();

                const response = await service.login(email, password);
            
                if(response.data.auth)
                    login(response.data.token)

                navigate('../dashboard', { replace: true });

            }catch(error){
                console.log(error);
                setError('Ocorreu um erro ao tentar logar');
            }
        }
    }
    return (
        (
            <Container>
                <Row className='justify-content-md-center'>
                    <Col xs={12} md={4}>
                        <BoxContent>
                            <img src={Logo} alt='SCEpi' height="50" />
                        </BoxContent>
                        <BoxForm>
                            <h2>Login</h2>
                            <p>Informe seus dados para autenticar.</p>
                            <Form onSubmit={handleSubmit}>
                                <FormGroup controlId='formBasicEmail'>
                                    <Form.Label>E-Mail</Form.Label>
                                    <Form.Control 
                                        type='email' 
                                        placeholder='Digite seu e-mail'
                                        onChange={e => setEmail(e.target.value)} 
                                    />
                                </FormGroup>

                                <FormGroup controlId='formBasicPassword'>
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control 
                                        type='password' 
                                        placeholder='Digite sua senha' 
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                </FormGroup>

                                <div className="d-grid gap-2">
                                    <Button variant='secondary' type='submit'>
                                        Fazer Login
                                    </Button>
                                </div>
                            </Form>
                        </BoxForm>
                        <BoxContent>
                            <p>Novo na plataforma ?</p>
                            <Link className='button' to='/signup/'>Crie sua conta agora</Link>
                        </BoxContent>
                    </Col>
                </Row>
            </Container>
        )
    )
} 

export default SigninForm;
