import { useLocation, Navigate } from "react-router-dom";
import { isAuthenticated } from '../services/auth';

export function RequireAuth({ children }) {
  
  let auth = isAuthenticated();  
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/" state={{ from: location }} replace />;    
  } else {
    return children;    
  }
}