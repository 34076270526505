import styled from 'styled-components';

export const Header = styled.div`
    background-color: #fff;
    border-bottom: solid 1px #dcdcdc;
`;

export const Logo = styled.img`
    max-width: 20%;
    heigth: 20px;
`;

