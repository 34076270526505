import Header from '../../../shared/header';
import { PageContent } from '../../../shared/styles';
import { Container } from 'react-bootstrap';

function DashboardForm(){
    return(
    <>
        <Header/>
        <PageContent>
            <Container>
                <h2>Dashboard</h2>
                <p> Notificações - Cockpit</p>
            </Container>
        </PageContent>
    </>
    )
}

export default DashboardForm;